import { ref, reactive } from 'vue'

export const api = ref('https://api.ciang.de')
//export const api = ref('http://127.0.0.1:8080')

export const store = reactive({
    // 用户名
    username: '',
    token: null,
    articleList: [],
    pause: false,

    user: null,

    // 测试信息
    record: {
        // 测试类型，0为英文打字，1为中文打字，2为竞赛，3为竞赛（邀请码）
        type: {
            value: 0,
            all: ['英文打字', '中文打字', '竞赛', '竞赛（邀请码）'],
        },

        // 当前所选文章
        article: null,

        // 测试时间
        time: 5,

        // 总用时
        taken_time: 0,

        // 时间线
        timeline: [],
    },
})