import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import TestView from '../views/TestView.vue'
import RankView from '../views/RankView.vue'
import Rank120View from '../views/Rank120View.vue'
import ListView from '../views/ListView.vue'
import ReportView from '../views/ReportView.vue'
import ArticleView from '../views/ArticleView.vue'
import MineView from '../views/MineView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            allow: true,
            title: '打字派 - 首页'
        }
    },
    {
        path: '/test',
        name: 'test',
        component: TestView,
        meta: {
            allow: true,
            title: '打字派 - 演习场'
        },
        beforeEnter: (to: any, from: any) => {
            if (from.meta.allow) return true;
            return { 'path': '/' };
        },
    },
    {
        path: '/rank',
        name: 'rank',
        component: RankView,
        meta: {
            allow: true,
            title: '打字派 - 排行榜'
        },
    },
    {
        path: '/rank120/:day/:type',
        name: 'rank120',
        component: Rank120View,
        meta: {
            allow: true,
            title: '打字派 - 排行榜'
        },
    },
    {
        path: '/list',
        name: 'list',
        component: ListView,
        meta: {
            allow: true,
            title: '打字派 - 结果列表'
        },
    },
    {
        path: '/report',
        name: 'report',
        component: ReportView,
        meta: {
            allow: true,
            title: '打字派 - 成绩报告'
        },
    },
    {
        path: '/article',
        name: 'article',
        component: ArticleView,
        meta: {
            allow: true,
            title: '打字派 - 文章编辑'
        },
    },
    {
        path: '/mine',
        name: 'mine',
        component: MineView,
        meta: {
            allow: true,
            title: '打字派 - 个人信息'
        },
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    linkActiveClass: 'active',
})


router.beforeEach((to, from, next) => {
    let title = to.meta.title;
    if (title === undefined || title === null) {
        title = '默认标题';
    }

    if (typeof title === 'string') {
        document.title = title;
    } else {
        console.error('预期字符串类型的title，但实际是：', title);
    }
    next();
});

export default router