import cookie from 'js-cookie';

export function GetName() {
    return cookie.get('username');
}

export function SetName(name) {
    return cookie.set('username', name);
}

export function RemoveName() {
    return cookie.remove('username');
}

export function GetToken() {
    return cookie.get('token', null);
}

export function SetToken(token) {
    return cookie.set('token', token);
}

export function RemoveToken() {
    return cookie.remove('token');
}